import image from "../photo1.jpg";
import textile from "../textile1.jpg";
import food from "../foodandbeverges1.avif";
import pharma from "../Pharmaceutical.jpeg";
import cosmetic from "../cosmetic.avif";

const Application = () => {
  return (
    <div
      style={{
        background: "#f9f9f9",
        padding: "4rem 0",
        width: "100%",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{ padding: "0 1rem", width: "100%", boxSizing: "border-box" }}
      >
        <p>
          <b
            style={{
              fontSize: "clamp(32px, 5vw, 55px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "2rem",
              marginTop: "0",
              color: "#333",
            }}
          >
            INDUSTRIES
            <div
              style={{
                width: "116px",
                height: "10px",
                backgroundColor: "#41D3E1",
              }}
            ></div>
          </b>
        </p>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
          gap: "2rem",
          padding: "0 1rem",
          maxWidth: "1400px",
          margin: "0 auto",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <div className="application-card">
          <img className="res-logo" alt="res-logo" src={textile}></img>
          <h3>Textile</h3>
          <p>
            TKP is widely used in the textile industry as a sizing material,
            which helps in improving the strength, smoothness, and appearance of
            yarns and fabrics. It contributes to the overall performance and
            durability of textile products.
          </p>
        </div>

        <div className="application-card">
          <img className="res-logo" alt="res-logo" src={food}></img>
          <h3>Food and Beverage</h3>
          <p>
            Our tamarind kernel powder acts as a natural thickening agent,
            stabilizer, and emulsifier in food and beverage products. It
            enhances the texture, shelf life, and overall quality of a wide
            range of edible items.
          </p>
        </div>

        <div className="application-card">
          <img className="res-logo" alt="res-logo" src={pharma}></img>
          <h3>Pharmaceutical</h3>
          <p>
            We produce tamarind kernel powder with pharmaceutical-grade
            standards to meet the requirements of the pharmaceutical industry.
            It is utilized in various formulations, including tablets, capsules,
            and topical creams, for its binding, disintegrating, and thickening
            properties.
          </p>
        </div>

        <div className="application-card">
          <img className="res-logo" alt="res-logo" src={cosmetic}></img>
          <h3>Cosmetics and Personal Care</h3>
          <p>
            TKP finds application in the cosmetics and personal care industry as
            a natural thickening agent, stabilizer, and texturizer in products
            like creams, lotions, shampoos, and soaps.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Application;
