import { Link } from "react-router-dom";
import logo from "./../logo.jpg";
import React from "react";
import { useState, useEffect } from "react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection);
      return () => {
        window.removeEventListener("scroll", updateScrollDirection);
      };
    }, [scrollDirection]);

    return scrollDirection;
  }

  const scrollDirection = useScrollDirection();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className={`header ${scrollDirection === "down" ? "down" : "show"}`}>
      <div className="logo-cotainer">
        <img className="logo" alt="logo" src={logo} />
      </div>
      <div className="menu-toggle" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={`nav-items ${isMenuOpen ? "active" : ""}`}>
        <ul>
          <li onClick={closeMenu}>
            <Link className="link" to="/">
              Home
            </Link>
          </li>
          <li onClick={closeMenu}>
            <a className="link" href="#contact">
              Contact Us
            </a>
          </li>
          <li onClick={closeMenu}>
            <a className="link" href="#products">
              Products
            </a>
          </li>
          <li onClick={closeMenu}>
            <Link className="link" to="#">
              About Us
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
