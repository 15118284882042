/* eslint-disable jsx-a11y/iframe-has-title */
const ContactUs = () => {
  return (
    <>
      <div
        style={{
          background: "#41D3E1",
          width: "100%",
          minHeight: "50%",
          padding: "2rem 1rem",
        }}
        id="contact"
      >
        <p>
          <b
            style={{
              fontSize: "clamp(32px, 5vw, 55px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "2rem",
            }}
          >
            CONTACT
            <div
              style={{
                width: "116px",
                height: "10px",
                backgroundColor: "black",
              }}
            ></div>
          </b>
        </p>

        <div style={{ width: "100%", maxWidth: "1200px", margin: "0 auto" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235.56401446750044!2d74.69256746328098!3d19.150416573786107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdcbb5bdb549817%3A0xa52bd92825737043!2sAdinath%20Processing%20Industries%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1701245955905!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0, paddingTop: "20px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: "2rem",
            padding: "2rem 1rem",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          <div>
            <h2>Inquiries</h2>
            <p>
              For any inquiries, questions or commendations, <br />
              please call: <br />
              <a
                href="tel:+919422224866"
                style={{ color: "black", fontSize: "clamp(18px, 2vw, 21px)" }}
              >
                +91 - 9422224866
              </a>
            </p>
          </div>

          <div>
            <h2>Office </h2>{" "}
            <p>
              Plot no C-31, MIDC, Ahilyanagar (Ahmednagar), MH, India - 414001
            </p>
            <a
              href="mailto:apipl.tkp@gmail.com"
              style={{ color: "black", fontSize: "clamp(18px, 2vw, 21px)" }}
            >
              apipl.tkp@gmail.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
