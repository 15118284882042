import CarouselLocal from "./CarouselLocal";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import image1 from "../photo1.jpg";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Application from "./Application";
import Products from "./Products";
import Vision from "./Vision";

const Home = () => {
  return (
    <div className="container" style={{ width: "100%", overflow: "hidden" }}>
      <div className="home-heading">
        <div className="home-content">
          <div className="heading">
            Welcome to Adinath Processing Industries
          </div>
        </div>
      </div>

      {/* <CarouselLocal /> */}
      <div style={{ width: "100%", overflow: "hidden" }}>
        <div className="home-description">
          <div className="story-content">
            <div>
              <p>
                <b
                  style={{
                    fontSize: "clamp(32px, 5vw, 55px)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingBottom: "2rem",
                    color: "#333",
                  }}
                >
                  OUR <br /> STORY
                  <div
                    style={{
                      width: "116px",
                      height: "10px",
                      backgroundColor: "#41D3E1",
                    }}
                  ></div>
                </b>
              </p>

              <p
                style={{
                  lineHeight: "1.8",
                  fontSize: "clamp(14px, 2vw, 16px)",
                  color: "#666",
                }}
              >
                At Adinath Processing Industries, our unwavering commitment to
                delivering high-quality Tamarind Kernel Powder (TKP) is a
                testament to our precision and expertise in the field. As a
                prominent manufacturer headquartered in Ahmednagar, India, we
                have successfully positioned ourselves as a trusted industry
                leader, renowned for providing top-notch products to our valued
                customers worldwide. Our dedication to excellence begins with
                our meticulous production process, where every step is executed
                with precision and care. We prioritize quality at every stage of
                TKP production, ensuring that the final product meets and
                exceeds the expectations of our discerning clientele. As we
                continue to grow and innovate, our mission remains steadfast—to
                be the preferred choice for high-quality Tamarind Kernel Powder,
                offering reliability, excellence, and a commitment to customer
                satisfaction that sets us apart in the industry.
              </p>
            </div>
          </div>
          {/* <img src={image1} alt="photo1" style={{ width: "100%" }} /> */}
        </div>

        <Products />
        <div
          style={{
            padding: "4rem 1rem",
            maxWidth: "1200px",
            margin: "0 auto",
            background: "#f9f9f9",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <p
            style={{
              textAlign: "left",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <b
              style={{
                fontSize: "clamp(32px, 5vw, 55px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "left",
                paddingBottom: "2rem",
                color: "#333",
              }}
            >
              Our Commitment to <br />{" "}
              <span style={{ color: "#41D3E1" }}>
                Quality & Sustainable Practices
              </span>
            </b>
            <p
              style={{
                fontSize: "clamp(14px, 2vw, 16px)",
                lineHeight: "1.8",
                color: "#666",
              }}
            >
              At Adinath Processing Industries, our commitment to excellence is
              evident in our stringent quality control measures applied at every
              production stage, ensuring that customers receive the finest
              Tamarind Kernel Powder in the market. Our state-of-the-art
              manufacturing facility, equipped with advanced machinery and
              technology, upholds the highest quality standards. Additionally,
              we prioritize sustainability and environmentally friendly
              practices. Our manufacturing processes are designed to minimize
              waste and optimize resource utilization. We source raw materials
              from trusted suppliers who share our dedication to sustainability.
              By adopting eco-friendly measures, we contribute to a greener
              future while upholding the highest standards of product
              excellence.
            </p>
          </p>
        </div>

        {/* <p>
          <b>Sustainable Practices</b>
        </p>
        We are committed to sustainable and environmentally friendly practices.
        Our manufacturing processes are designed to minimize waste and optimize
        resource utilization. We source our raw materials from trusted suppliers
        who share our commitment to sustainability. By adopting eco-friendly
        measures, we contribute to a greener future while maintaining the
        highest standards of product excellence. */}
        {/* <p>
          <b>Customer Satisfaction</b>
        </p>
        Customer satisfaction is at the heart of everything we do. We strive to
        understand the unique requirements of our clients and provide tailored
        solutions to meet their needs. Our dedicated team of professionals works
        closely with customers, ensuring prompt delivery and excellent service.
        <p>
          <b>Contact Us</b>
        </p>
        If you have any inquiries or would like to learn more about our Tamarind
        Kernel Powder, please don't hesitate to get in touch with us. Our
        knowledgeable team is always ready to assist you. We look forward to
        serving you and building a long-lasting partnership.
        <h2>Thank you for visiting Adinath Processing Industries.</h2> */}
      </div>
      <Application />

      <ContactUs />
    </div>
  );
};
export default Home;
