const Footer = () => {
  return (
    <div
      className="footer"
      style={{
        padding: "1rem",
        fontSize: "clamp(14px, 2vw, 16px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "0.5rem",
      }}
    >
      <i className="fa-solid fa-heart"></i>
      <span>Copyright © 2025</span>
      <strong>Adinath Processing Industries Pvt .Ltd</strong>
    </div>
  );
};

export default Footer;
